/* GLOBAL */
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: #f6f6f6;
}

* {
  box-sizing: border-box;
}

/*** FONTS ***/
@font-face {
  font-family: poppins_bold;
  src: url(../assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: poppins_medium;
  src: url(../assets/fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: poppins_regular;
  src: url(../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: poppins_semiBold;
  src: url(../assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: poppins_light;
  src: url(../assets/fonts/Poppins-Light.ttf);
}
